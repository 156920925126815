import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilService } from '../services/util.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBiDeactivateGuard implements CanActivate, CanDeactivate<unknown> {
  alreadyActivated: boolean;
  constructor(private util:UtilService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.alreadyActivated = true;
      return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      setTimeout(() => {
        if(!this.alreadyActivated){
          return this.util.handleRequest('post','a3s_powerBi_triggerAction',['pause'],null, null, null, true).then(res=>{
            this.util.success(`Power BI paused successfully!`) 
            return true;
          },err=>{
            this.util.error(`Failed to pause power BI.`)
          })
        }
      }, 60000);
      this.alreadyActivated = false;
      return true;
  }

  // triggerPowerBiAction(type){
  //   let successMsg,errorMsg;
  //   switch(type){
  //     case 'start' : successMsg = 'started';errorMsg = 'start';break;
  //     case 'pause' : successMsg = 'paused';errorMsg = 'pause';break;
  //   }
  //   return this.util.handleRequest('post','a3s_powerBi_triggerAction',[type],null, null, null, true).then(res=>{
  //     type == 'pause' ? this.util.success(`Power BI ${successMsg} successfully!`) : null
  //   },err=>{
  //     // this.util.error(`Failed to ${errorMsg} powerBi.`)
  //   })
  // }
}
